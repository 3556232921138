.contentWrapper {
  @apply flex flex-col;

  div {
    @apply my-3 mx-auto w-full;
  }

  p {
    @apply my-3 text-lg;
  }

  h1,
  h2 {
    @apply my-3 text-4xl;
  }

  h3 {
    @apply my-3 text-3xl;
  }

  h4 {
    @apply my-3 text-2xl;
  }

  h5 {
    @apply my-3 text-xl font-bold;
  }

  h6 {
    @apply my-3 text-xl;
  }

  img,
  iframe {
    @apply my-3 mx-auto;
  }

  ul,
  ol {
    @apply mb-4 pl-8;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  li {
    @apply my-2 text-lg;
  }

  blockquote {
    @apply my-3 border-l-2 p-6 px-10 text-xl;
  }

  hr {
    @apply my-3;
  }
}
