/* stylelint-disable declaration-colon-newline-after */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts. */
@font-face {
  font-family: "GT Walsheim Pro";
  src: url("/fonts/GT-Walsheim-Pro-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim Pro";
  src: url("/fonts/GT-Walsheim-Pro-Light-Oblique.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "GT Walsheim Pro";
  src: url("/fonts/GT-Walsheim-Pro-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim Pro";
  src: url("/fonts/GT-Walsheim-Pro-Regular-Oblique.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "GT Walsheim Pro";
  src: url("/fonts/GT-Walsheim-Pro-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim Pro";
  src: url("/fonts/GT-Walsheim-Pro-Medium-Oblique.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "GT Walsheim Pro";
  src: url("/fonts/GT-Walsheim-Pro-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim Pro";
  src: url("/fonts/GT-Walsheim-Pro-Bold-Oblique.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

html[data-mode="dark"] {
  @apply bg-slate-800 text-white;
}

@layer base {
  html {
    font-family: "GT Walsheim Pro", sans-serif;

    @apply text-grey-6;
    line-height: 1.25rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-medium;
    @apply text-blue-8;
  }

  a {
    @apply text-blue-5 hover:underline;
  }

  p.white {
    @apply text-white;

    a {
      @apply text-pink-bold hover:underline;
    }
  }

  code {
    @apply rounded-md bg-red-50 px-2 py-1 font-mono text-base text-red-600;
  }

  .w-bleed-full {
    /* scrollbar-width set in _app and necessary to get rid of side scrolling */
    margin-left: calc(50% - 50vw + (var(--scrollbar-width) / 2) + 0.1px);
    margin-right: calc(50% - 50vw + (var(--scrollbar-width) / 2) + 0.1px);
  }
  .w-bleed-right {
    /* scrollbar-width set in _app and necessary to get rid of side scrolling */
    margin-right: calc(50% - 50vw + (var(--scrollbar-width) / 2));
  }
  .dark-green-image {
    filter: brightness(0) saturate(100%) invert(15%) sepia(11%) saturate(4622%)
      hue-rotate(148deg) brightness(98%) contrast(93%);
  }
  .white-image {
    filter: brightness(0%) invert(100%);
  }
  .blue-6-image {
    filter: brightness(0) saturate(100%) invert(26%) sepia(26%) saturate(3165%)
      hue-rotate(155deg) brightness(96%) contrast(98%);
  }
  .blue-3-image {
    filter: invert(77%) sepia(43%) saturate(229%) hue-rotate(137deg)
      brightness(100%) contrast(87%);
  }
  .blue-5-image {
    filter: invert(34%) sepia(100%) saturate(400%) hue-rotate(139deg)
      brightness(96%) contrast(95%);
  }
  .icon-shape-correction > img {
    margin-bottom: -4px;
  }
  .text-grey-3 a {
    color: #9bd8de;
  }
  .text-white a {
    color: #9bd8de;
  }
  .header-background {
    background: rgba(255, 255, 255, 0.88);
    backdrop-filter: blur(10px);
  }
  .underline-links a {
    text-decoration: underline;
  }
  .has-text-color.has-white-color {
    color: white;
  }
  .has-text-color.has-white-color a {
    color: #9bd8de;
  }

  .rtb-icon-feature-strong-dark strong {
    color: #ffffff;
  }
  .rtb-icon-feature-strong strong {
    color: #09363f;
  }

  :root {
    --tooltip-margin: 10px;
    --tooltip-arrow-size: 6px;
  }

  .tooltip-wrapper {
    display: inline-block;
    position: relative;
  }

  .tooltip-tip {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    font-size: 14px;
    line-height: 1;
    z-index: 100;
    width: max-content;
    text-align: center;
    line-height: 17px;
  }

  .tooltip-tip::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1 + 1px);
  }

  .tooltip-tip.tooltip-tip-top {
    bottom: calc(100% + var(--tooltip-margin));
  }
  .tooltip-tip.tooltip-tip-top::before {
    top: 100%;
  }

  .tooltip-tip.tooltip-tip-right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  .tooltip-tip.tooltip-tip-right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .tooltip-tip.tooltip-tip-bottom {
    top: calc(100% + var(--tooltip-margin));
  }
  .tooltip-tip.tooltip-tip-bottom::before {
    bottom: 100%;
  }

  .tooltip-tip.tooltip-tip-left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  .tooltip-tip.tooltip-tip-left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  html:has(.dialog[open]) {
    overflow: hidden;
  }
  .dialog::backdrop {
    background: rgba(0, 0, 0, 0.6);
  }
  .h-offer-overlay-image {
    height: 30vh;
    height: 30dvh;
  }
  .max-h-offer-overlay-content-full {
    max-height: calc(100vh - 32px);
    max-height: calc(100dvh - 32px);
  }
  .h-offer-overlay-content {
    height: calc(70vh - 32px);
    height: calc(70dvh - 32px);
  }
  .homepage-hero-chip {
    --offset: 2px;
  }
  .homepage-hero-chip::before {
    background: conic-gradient(transparent 180deg, #b3e3ef, #f0f8fa);
  }
}
